import React, { useEffect, useState } from "react";
import HostNav from "../../components/hostNav/HostNav";
import "./addTour.scss";
import axios from "axios";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useNavigate, useParams } from "react-router-dom";

const AddBlog = () => {
  const {id} = useParams()
  const [heading, setHeading] = useState("");
  const [blog, setBlog] = useState("");
  const [category, setCategory] = useState("");
  const [date, setDate] = useState("");
  const [message, setMessage] = useState("");
  const [blogData, setBlogData] = useState([]);
  const [blogPhotos,setBlogPhotos] = useState([])

    const navigate = useNavigate();

  const addNewBlog = async (e) => {
    e.preventDefault();

    const blogData = {
      heading,
      blog,
      category,
      date,
      blogPhotos,
    };

    try {
      await axios.post("/addBlog", blogData);
      setMessage("Tour Successfuly upload");
    } catch (error) {
      console.log(error);
    }
    setHeading("");
    setBlog("");
    setCategory("");
    setDate("");
    setMessage("");
    setBlogPhotos("");
  };

  useEffect(() => {
    const getBlogs = async () => {
      try {
        await axios.get("/getBlog").then((response) => {
          setBlogData(response.data);
        });
      } catch (error) {
        console.log(error);
      }
    };
    getBlogs();
  }, []);


  async function uploadPhoto(e) {
    const files = e.target.files;
    const data = new FormData();

    for (let i = 0; i < files.length; i++) {
      data.append("blog", files[i]);
    }
    await axios
      .post("/blogImgUpload", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const { data: filenames } = response;
        setBlogPhotos((prev) => {
          return [...prev, ...filenames];
        });
      });
  }
   function removePhoto(e, filename) {
     e.preventDefault();
     setBlogPhotos([...blogPhotos.filter((photo) => photo !== filename)]);
   }


     useEffect(() => {
       if (!id) {
         return;
       }

       const getSingalTour = async () => {
         try {
            const response = await axios.get(`/getSingalBlog/${id}`);
           const { data } = response;
           setBlog(data.blog);
           setHeading(data.heading);
           setCategory(data.category)
           setBlogPhotos(data.blogPhotos)
           setDate(data.date);

         } catch (error) {
           console.log(error);
         }
       };

       getSingalTour();
     }, [id]);



     const updateBlog = async (e) => {
       e.preventDefault();
       const list = {
         heading,
         blog,
         category,
         date,
         blogPhotos,
       };
       try {
         await axios.put(`/update_blog/${id}`, list);
         navigate("/all-blog");
       } catch (error) {
         console.log(error);
       }
     };







  return (
    <div className="__fw" style={{ minHeight: "100dvh" }}>
      <div className="__mw">
        <HostNav />

        <div className="admin_listings">
          <div className="admin_listings_head">
            <div
              className="listings_item_text"
              style={{ marginBottom: "20px" }}
            >
              <h2>Add New Blog</h2>
            </div>
            <div></div>
          </div>
        </div>
        <div className="tour_form">
          <div className="tour_info_form">
            <form onSubmit={addNewBlog}>
              <div className="tour_info_form_in">
                <div className="tour_info_form_in_data">
                  <label>Heading</label>
                  <input
                    required
                    type="text"
                    value={heading}
                    onChange={(e) => setHeading(e.target.value)}
                  />
                </div>
              </div>
              <div className="tour_info_form_in">
                <div className="tour_info_form_in_data">
                  <label>Blog</label>
                  <textarea
                    required
                    type="text"
                    value={blog}
                    onChange={(e) => setBlog(e.target.value)}
                  />
                </div>
              </div>

              <div className="tour_info_form_in">
                <div className="tour_info_form_in_data">
                  <label>Blog Category</label>
                  <select
                    required
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Destination Guides">
                      Destination Guides
                    </option>
                    <option value="Cultural Experiences">
                      Cultural Experiences
                    </option>
                    <option value="Adventure and Nature">
                      Adventure and Nature
                    </option>
                    <option value="Travel Tips">Travel Tips</option>
                    <option value="Culinary Journeys">Culinary Journeys</option>
                    <option value="Historical Insights">
                      Historical Insights
                    </option>
                    <option value="Accommodation Reviews">
                      Accommodation Reviews
                    </option>
                    <option value="Travel Stories">Travel Stories</option>
                    <option value="Sustainable Tourism">
                      Sustainable Tourism
                    </option>
                    <option value="Events and Festivals">
                      Events and Festivals
                    </option>
                  </select>
                </div>
              </div>

              <div className="tour_info_form_in">
                <div className="tour_info_form_in_data">
                  <label>Date</label>
                  <input
                    required
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                  />
                </div>
              </div>
              <div className="h_photo_grid">
                {blogPhotos?.length > 0 &&
                  blogPhotos.map((link) => (
                    <div className="h_img_over" key={link}>
                      <img
                        src={
                          `${process.env.REACT_APP_IMAGE_BLOG_UPLOAD_LINK}/` +
                          link
                        }
                        alt=""
                      />
                      <div className="h_trash_icon">
                        <button onClick={(e) => removePhoto(e, link)}>
                          <DeleteOutlineIcon style={{ fontSize: "medium" }} />
                        </button>
                      </div>
                    </div>
                  ))}
                <div className="tour_info_form_in">
                  <div className="tour_info_form_in_data">
                    <label>Upload Images</label>
                    <label className="label">
                      <input
                        onChange={uploadPhoto}
                        type="file"
                        multiple
                        className="btn_upload"
                      />
                      Upload
                    </label>
                  </div>
                </div>
              </div>
              {!id ? (
                <button className="_button">Add Blog</button>
              ) : (
                <button className="_button" onClick={updateBlog}>
                  Update Tour
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBlog;
