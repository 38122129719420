import React, { useContext } from "react";
import "./allGuests.scss";

import { Link } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import useFetch from "../../hooks/useFetch";
import { AuthContext } from "../../context/AuthContext";
import HostNav from "../../components/hostNav/HostNav";

const AllGuests = () => {
  const { user } = useContext(AuthContext);
  const id = user.id;
  const { data, loading, error, reFetch } = useFetch(`/getAllGuest`);

  // ! grid

  const columns = [
    

    { field: "fName", headerName: "First Name", width: "200" },
    { field: "lName", headerName: "Last Name", width: "200" },
    { field: "country", headerName: "Country", width: "200" },
    {
      field: "email",
      headerName: "Email",
      width: "200",
    },
    {
      field: "phone",
      headerName: "Phone",
      width: "200",
    },
  ];

  return (
    <div className="__fw" style={{ minHeight: "100dvh" }}>
      <div className="__mw">
        <HostNav />
        <div className="admin_listings">
          <div className="admin_listings_head">
            <div
              className="listings_item_text"
              style={{ marginBottom: "20px" }}
            >
              <h2>All Guests</h2>
            </div>
            <div></div>
          </div>

          <div className="listing_list">
            <DataGrid
              key={data._id}
              // onCellClick={onEditClick}
              rows={data}
              columns={columns}
              pageSize={5}
              over
              disableRowSelectionOnClick
              initialState={{
                pagination: {
                  paginationModel: {
                    //pageSize:
                  },
                },
              }}
              getRowId={(row) => row._id}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllGuests;
