import React, { useContext, useState } from "react";
import "./login.scss";
import { Link, Navigate, useNavigate } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";

const initialState = {
  email: "",
  password: "",
};

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error,setError] = useState("")


  const { loading,  dispatch } = useContext(AuthContext);
  // console.log(error);

  const navigate = useNavigate();

  const credentials = {
    email,
    password,
  };


  const handleClick = async (e) => {
    e.preventDefault();
    dispatch({ type: "LOGIN_START" });
    try {
      const res = await axios.post("/login", credentials);
      dispatch({ type: "LOGIN_SUCCESS", payload: res.data.details });
      // console.log(res.data.details);
      navigate("/today-bookings");
    } catch (err) {
      dispatch({ type: "LOGIN_FAILURE", payload: err.response.data });
      setError("Invalid email or password");

    }
  };

  return (
    <div className="__fw" style={{ minHeight: "100dvh" }}>
      <div className="__mw">
        <div className="bg-tex">
          <div class="ls-Background-background">
            <div class="ls-ls-Background-background"></div>
            <div
              class="ls-Background-background-s-left"
              style={{ background: "var(--blue)b4" }}
            ></div>
            <div
              class="ls-Background-background-f-s-rihgt"
              style={{ background: "var(--orange)b8" }}
            ></div>
          </div>
        </div>
        <div className="login">
          <div className="_log_in">
            <>
              <div className="_in_h">
                <img src={require("../../img/bk_logo.png")} alt="" />
                <p>
                  {" "}
                  Admin Console <em>Sign in</em>{" "}
                </p>
              </div>
              <form onSubmit={handleClick}>
                <div className="input_wrp">
                  <label>E mail</label>
                  <input
                    type="email"
                    placeholder="your@email.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="input_wrp">
                  <label>Password</label>
                  <input
                    type="password"
                    placeholder="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <small style={{ color: "red" }}>{error}</small>
                <br />
                <button className="button">Sign in</button>
                {/* <div className="_rg_pg">
                  <span>
                    Don't have an account yet?{" "}
                    <Link style={{ color: "#40c0f0" }} to={"/register"}>
                      {" "}
                      Register now
                    </Link>
                  </span>
                </div> */}
              </form>
            </>
            {/* <div className="cross_mark">
              <div className="_icon">
                <Link to={"/"}>
                  <ClearIcon className="icon" fontSize="20" color="black" />
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
