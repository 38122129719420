import React, { useEffect, useState } from "react";
import "./addTour.scss";
import HostNav from "../../components/hostNav/HostNav";
import { IoIosCloseCircleOutline } from "react-icons/io";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";




const AddTour = () => {
  const id = useParams();
  const singleTourId = id.id

  const navigate = useNavigate()

  const [itinerary, setItinerary] = useState([
    { date: "", description: "", heading: "" },
  ]);
  const [including, setIncluding] = useState([
    { number: "", description: "", status: "" },
  ]);
  const [heading, setHeading] = useState("");
  const [subHeading, setSubHeading] = useState("");
  const [tourMap, setTourMap] = useState("");
  const [tourLink, setTourLink] = useState("");
  const [category, setCategory] = useState("");
  const [types, setTypes] = useState("");
  const [description, setDescription] = useState("");
  const [response, setResponse] = useState("");
  const [message,setMessage] = useState("")
  const [price, setPrice] = useState("");
  const [discount, setDiscount] = useState("");
  const [location,setLocation] = useState("")

  const [tourPhotos, setTourPhotos] = useState([]);

  const handleScroll = () => {
    window.scrollTo(0, 0);
  };

  const handleChange = (index, event) => {
    event.preventDefault();
    const values = [...itinerary];
    values[index][event.target.name] = event.target.value;
    setItinerary(values);
  };

  const handleAddFields = (event) => {
    event.preventDefault();
    setItinerary([...itinerary, { date: "", description: "", heading: "" }]);
  };

  const handleRemoveFields = (index) => {
    const values = [...itinerary];
    values.splice(index, 1);
    setItinerary(values);
  };

  const handleChangeInclu = (index, event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const values = [...including];
    values[index][name] = value;
    setIncluding(values);
  };

  const handleAddFieldsInclu = (event) => {
    event.preventDefault();
    setIncluding([...including, { number: "", description: "", status: "" }]);
  };

  const handleRemoveFieldsInclu = (index) => {
    const values = [...including];
    values.splice(index, 1);
    setIncluding(values);
  };

  const tourData = {
    itinerary,
    including,
    heading,
    subHeading,
    tourMap,
    tourLink,
    category,
    types,
    description,
    tourPhotos,
    price,
    discount,
    location,
  };


  const addTour = async (e) => {
    e.preventDefault();
    try {
      await axios.post("/addtour", tourData)
      setMessage("Tour Successfuly upload")

    } catch (error) {
      console.log(error);
    }
    setItinerary([{ date: "", description: "", heading: "" }]);
    setIncluding([{ number: "", description: "", status: "" }]);
    setHeading("")
    setSubHeading("")
    setTourMap("")
    setTourLink("")
    setTourPhotos("")
    setTypes("")
    setDescription("")
    setCategory("")
    setPrice("");
    setLocation("");
    setDiscount("");
  };

  useEffect(() => {
    if (!singleTourId) {
      return;
    }

    const getSingalTour = async () => {
      try {
        const response = await axios.get(`/get_singal_tour/${singleTourId}`);
        const { data } = response;
        setItinerary(data.itinerary);
        setIncluding(data.including);
        setHeading(data.heading);
        setSubHeading(data.subHeading);
        setTourMap(data.tourMap);
        setTourLink(data.tourLink);
        setTourPhotos(data.tourPhotos);
        setTypes(data.types);
        setDescription(data.description);
        setCategory(data.category);
        setPrice(data.price)
        setLocation(data.location)
        setDiscount(data.discount)
      } catch (error) {
        console.log(error);
      }
    };

    getSingalTour();
  }, [singleTourId]);



   const updateTour = async (e) => {
     e.preventDefault();
     const list = {
       itinerary,
       including,
       heading,
       subHeading,
       tourMap,
       tourLink,
       category,
       types,
       description,
       tourPhotos,
       price,
       discount,
       location,
     };
     try {
       await axios.put(`/update_tour/${singleTourId}`, list);
        navigate("/all-tour");
     } catch (error) {
       console.log(error);
     }
   };



 
  async function uploadPhoto(e) {
    const files = e.target.files;
    const data = new FormData();

    for (let i = 0; i < files.length; i++) {
      data.append("tour", files[i]);
    }
    await axios
      .post("/tourUpload", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const { data: filenames } = response;
        setTourPhotos((prev) => {
          return [...prev, ...filenames];
        });
      });
  }
  function removePhoto(e, filename) {
    e.preventDefault();
    setTourPhotos([...tourPhotos.filter((photo) => photo !== filename)]);
  }

  function selectMainPhoto(e, filename) {
    e.preventDefault();
    const addPhotoWithoutSelected = tourPhotos.filter(
      (photo) => photo !== filename
    );
    const newAddedPhotos = [filename, ...addPhotoWithoutSelected];
    setTourPhotos(newAddedPhotos);
  }

  return (
    <div className="__fw" style={{ minHeight: "100dvh" }}>
      <div className="__mw">
        <HostNav />
        <div className="admin_listings">
          <div className="admin_listings_head">
            <div
              className="listings_item_text"
              style={{ marginBottom: "20px" }}
            >
              <h2>Add New Tours</h2>
            </div>
            <div></div>
          </div>
          <div className="tour_form">
            <div className="tour_info_form">
              <form onSubmit={addTour}>
                <div className="form_in">
                  <div className="tour_info_form_in">
                    <div className="tour_info_form_in_data">
                      <label>Heading</label>
                      <input
                        required
                        type="text"
                        value={heading}
                        onChange={(e) => setHeading(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="tour_info_form_in">
                    <div className="tour_info_form_in_data">
                      <label>Sub Heading</label>
                      <input
                        required
                        type="text"
                        value={subHeading}
                        onChange={(e) => setSubHeading(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="tour_info_form_in">
                    <div className="tour_info_form_in_data">
                      <label>Tour Map Link</label>
                      <input
                        required
                        type="text"
                        value={tourMap}
                        onChange={(e) => setTourMap(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="tour_info_form_in">
                    <div className="tour_info_form_in_data">
                      <label>Tour Link</label>
                      <input
                        required
                        type="text"
                        value={tourLink}
                        onChange={(e) => setTourLink(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="tour_info_form_in">
                    <div className="tour_info_form_in_data">
                      <label>Price</label>
                      <input
                        required
                        type="text"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="tour_info_form_in">
                    <div className="tour_info_form_in_data">
                      <label>Discount</label>
                      <input
                        required
                        type="text"
                        value={discount}
                        onChange={(e) => setDiscount(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="tour_info_form_in">
                    <div className="tour_info_form_in_data">
                      <label>Location</label>
                      <input
                        required
                        type="text"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="tour_info_form_in">
                    <div className="tour_info_form_in_data">
                      <label>Tour Category</label>
                      <select
                        required
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                      >
                        <option value="">Select</option>
                        <option value="2 Days">2 Days</option>
                        <option value="3 Days">3 Days</option>
                        <option value="5 Days">5 Days</option>
                        <option value="6 Days">6 Days</option>
                        <option value="7 Days">7 Days</option>
                        <option value="8 Days">8 Days</option>
                        <option value="9 Days">9 Days</option>
                        <option value="10 Days">10 Days</option>
                        <option value="12 Days">12 Days</option>
                        <option value="13 Days">13 Days</option>
                        <option value="14 Days">14 Days</option>
                        <option value="15 Days">15 Days</option>
                        <option value="28 Days">28 Days</option>
                      </select>
                    </div>
                  </div>
                  <div className="tour_info_form_in">
                    <div className="tour_info_form_in_data">
                      <label>Tour Types</label>
                      <select
                        required
                        value={types}
                        onChange={(e) => setTypes(e.target.value)}
                      >
                        <option value="">Select</option>
                        <option value="Women">Women</option>
                        <option value="General">General</option>
                        <option value="Solo">Solo</option>
                        <option value="VIP">VIP</option>
                        <option value="Adventure">Adventure</option>
                        <option value="Nature & Wild Life">
                          Nature & Wild Life
                        </option>
                        <option value="Honeymoon">Honeymoon</option>
                        <option value="Pilgrimage">Pilgrimage</option>
                        <option value="Ramayana">Ramayana</option>
                        <option value="Ayurveda & Wellness">
                          Ayurveda & Wellness
                        </option>
                        <option value="Study">Study</option>
                      </select>
                    </div>
                  </div>
                  <div className="tour_info_form_in">
                    <div className="tour_info_form_in_data">
                      <label>Tour Description</label>
                      <textarea
                        required
                        type="text"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="h_photo_grid">
                    {tourPhotos?.length > 0 &&
                      tourPhotos.map((link) => (
                        <div className="h_img_over" key={link}>
                          <img
                            src={
                              `${process.env.REACT_APP_TOUR_UPLOAD_LINK}/` +
                              link
                            }
                            alt=""
                          />
                          <div className="h_trash_icon">
                            <button onClick={(e) => removePhoto(e, link)}>
                              <DeleteOutlineIcon
                                style={{ fontSize: "medium" }}
                              />
                            </button>
                          </div>
                          <div className="h_star_icon">
                            <button onClick={(e) => selectMainPhoto(e, link)}>
                              {link === tourPhotos[0] && (
                                <StarIcon
                                  style={{
                                    fontSize: "medium",
                                    color: "var(--orange)",
                                  }}
                                />
                              )}
                              {link !== tourPhotos[0] && (
                                <StarBorderIcon
                                  style={{ fontSize: "medium" }}
                                />
                              )}
                            </button>
                          </div>
                        </div>
                      ))}
                    <div className="tour_info_form_in">
                      <div className="tour_info_form_in_data">
                        <label>Upload Images</label>
                        <label className="label">
                          <input
                            onChange={uploadPhoto}
                            type="file"
                            multiple
                            className="btn_upload"
                          />
                          Upload
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tour_info_form_in">
                  <div className="tour_info_form_in_data">
                    <label>Itinerary</label>

                    {itinerary.map((item, index) => (
                      <div key={index} className="add_tour">
                        <input
                          name="date"
                          placeholder="Date"
                          value={item.date}
                          onChange={(event) => handleChange(index, event)}
                        />
                        <input
                          name="description"
                          placeholder="Description"
                          value={item.description}
                          onChange={(event) => handleChange(index, event)}
                        />
                        <input
                          name="heading"
                          type="text"
                          placeholder="Heading"
                          value={item.heading}
                          onChange={(event) => handleChange(index, event)}
                        />
                        <div className="icon">
                          <IoIosCloseCircleOutline
                            size={20}
                            onClick={() => handleRemoveFields(index)}
                          />
                        </div>
                      </div>
                    ))}
                    <button className="button" onClick={handleAddFields}>
                      Add Item
                    </button>
                  </div>
                </div>

                <div className="tour_info_form_in">
                  <div className="tour_info_form_in_data">
                    <label>Including</label>

                    {including.map((item, index) => (
                      <div key={index} className="add_tour">
                        <input
                          name="number"
                          placeholder="Number"
                          value={item.number}
                          onChange={(event) => handleChangeInclu(index, event)}
                        />
                        <input
                          name="description"
                          placeholder="Description"
                          value={item.description}
                          onChange={(event) => handleChangeInclu(index, event)}
                        />
                        <div className="_tour_info_form_in">
                          <div className="tour_info_form_in_data">
                            <select
                              name="status"
                              value={item.status}
                              onChange={(event) =>
                                handleChangeInclu(index, event)
                              }
                            >
                              <option value="">Select</option>
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                            </select>
                          </div>
                        </div>
                        <div className="icon">
                          <IoIosCloseCircleOutline
                            size={20}
                            onClick={() => handleRemoveFieldsInclu(index)}
                          />
                        </div>
                      </div>
                    ))}

                    <button className="button" onClick={handleAddFieldsInclu}>
                      Add Item
                    </button>
                  </div>
                </div>
                {!singleTourId ? (
                  <button className="_button">Add Tour</button>
                ) : (
                  <button className="_button" onClick={updateTour}>
                    Update Tour
                  </button>
                )}
                <br />
                <span>{message}</span>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTour;
