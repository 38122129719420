import React, { useContext, useEffect, useState, useMemo } from "react";
import "./allHost.scss";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { MdOutlineEdit } from "react-icons/md";
import { IoIosCloseCircleOutline } from "react-icons/io";
import useFetch from "../../hooks/useFetch";
import { AuthContext } from "../../context/AuthContext";
import HostNav from "../../components/hostNav/HostNav";
import "../allHostList/allHost.scss";

const Pending = () => {
  const { user } = useContext(AuthContext);
  const [verify, setVerify] = useState("");
  const [showAddNew, setShowAddNew] = useState(false);
  const [itemEditId, setItemEditId] = useState("");
  const [response, setResponse] = useState("");
  const [ownerId, setOwnerId] = useState("");
  const [pendingListing, setPendingListing] = useState(null);

  const id = user.id;

  const { data, loading, error, reFetch } = useFetch(`/allPendingListing`);

  const onEditClick = (params) => {
    const getItem = params.row;
    setItemEditId(getItem.listingId);
    setOwnerId(getItem.owner);
    setShowAddNew(true);
    setPendingListing(getItem);
  };

 
  const handleListingChange = (event) => {
    setVerify(event.target.value);
  };

  const handleAddNew = () => {
    setShowAddNew(!showAddNew);
  };

  useEffect(() => {
    reFetch();
  }, [response]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const listData = {
      verify,
      itemEditId,
      ownerId,
    };
    try {
      const response = await axios.put(
        `/verifyListing/${itemEditId}/${ownerId}`,
        listData
      );
      setResponse(response.data);
      setShowAddNew(false);
      setItemEditId("");
      setVerify("");
    } catch (error) {
      console.error(error);
    }
  };

  

  const columns = useMemo(
    () => [
      { field: "listingId", headerName: "Listing ID", width: 250 },
      { field: "fName", headerName: "Name" },
      { field: "city", headerName: "City" },
      { field: "status", headerName: "Status" },
      { field: "percentage", headerName: "Guest %" },
      { field: "price", headerName: "Price - Rs" },
      { field: "owner", headerName: "Owner ID" },
    ],
    []
  );

  return (
    <div className="__fw" style={{ minHeight: "100dvh" }}>
      <div className="__mw">
        <HostNav />
        <div className="admin_listings">
          <div className="admin_listings_head">
            <div
              className="listings_item_text"
              style={{ marginBottom: "20px" }}
            >
              <h2>Pending listing</h2>
            </div>
            <div></div>
          </div>

          <div className="addNew">
            {showAddNew && pendingListing && (
              <div className="addNew_in">
                <div className="addNew_wrap">
                  <div className="profile_wrp">
                    <div className="p_wrp_hd">
                      <h3>{pendingListing.title}</h3>
                      <small>
                        {pendingListing.fName} {pendingListing.lName}
                      </small>
                      <small
                        style={{
                          margin: "5px 0px",
                          color: "red",
                          fontWeight: "600",
                        }}
                      >
                        {pendingListing.status}
                      </small>
                      <hr />
                      <div className="wrp_in">
                        <small>Owner ID</small>
                        <span>{pendingListing.owner}</span>
                      </div>
                      <div className="wrp_in">
                        <small>Listing ID</small>
                        <span>{pendingListing.listingId}</span>
                      </div>
                      <div className="mn_wrp_in">
                        <div className="wrp_in">
                          <small>Price</small>
                          <span>Rs. {pendingListing.price}</span>
                        </div>
                        <div className="wrp_in">
                          <small>Customer</small>
                          <span>{pendingListing.percentage}%</span>
                        </div>
                        <div className="wrp_in">
                          <small>Sale Price</small>
                          <span>Rs. {pendingListing.salePrice}</span>
                        </div>
                      </div>
                      <hr />
                      <div className="wrp_in">
                        <small>Category</small>
                        <span>{pendingListing.selectHomeCategory}</span>
                      </div>
                      <div className="wrp_in">
                        <small>Type of Place</small>
                        <span>{pendingListing.selectPlace}</span>
                      </div>
                      <div className="wrp_in">
                        <small>Address</small>
                        <span>
                          {pendingListing.province}, {pendingListing.city},{" "}
                          {pendingListing.address}, {pendingListing.country}
                        </span>
                      </div>
                      <hr />
                      <div className="wrp_in">
                        <small>Type of Accommodation</small>
                        <span>{pendingListing.type}</span>
                      </div>
                      <hr />
                      <div className="mn_wrp_in">
                        <div className="wrp_in">
                          <small>Bedrooms</small>
                          <span>{pendingListing.beadrooms}</span>
                        </div>
                        <div className="wrp_in">
                          <small>Bathrooms</small>
                          <span>{pendingListing.bathrooms}</span>
                        </div>
                        <div className="wrp_in">
                          <small>Beds</small>
                          <span>{pendingListing.beds}</span>
                        </div>
                        <div className="wrp_in">
                          <small>Adults</small>
                          <span>{pendingListing.adults}</span>
                        </div>
                        <div className="wrp_in">
                          <small>Children</small>
                          <span>{pendingListing.children}</span>
                        </div>
                        <div className="wrp_in">
                          <small>Infants</small>
                          <span>{pendingListing.infants}</span>
                        </div>
                        <div className="wrp_in">
                          <small>Share Bathrooms</small>
                          <span>{pendingListing.shareBathrooms}</span>
                        </div>
                      </div>
                      <hr />
                      <div className="mn_wrp_in_two">
                        <div className="wrp_in">
                          <small>CheckIn Time</small>
                          <span>{pendingListing.checkIn}</span>
                        </div>
                        <div className="wrp_in">
                          <small>CheckOut Time</small>
                          <span>{pendingListing.checkOut}</span>
                        </div>
                      </div>
                      <hr />
                      <div className="mn_wrp_in">
                        <div className="wrp_in">
                          <small>Itinerary</small>
                          <div>
                            {pendingListing.itinerary.map((item) => (
                              <span>{item}</span>
                            ))}
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="wrp_in">
                        <small>Description</small>
                        <span className="span">
                          {pendingListing.description}
                        </span>
                      </div>
                      <div className="wrp_in">
                        <small>Extra Info</small>
                        <span className="span">{pendingListing.extraInfo}</span>
                      </div>
                      <div className="wrp_in">
                        <small>House Rule</small>
                        <span className="span">{pendingListing.houseRule}</span>
                      </div>
                    </div>
                  </div>

                  <div className="form_wrp">
                    <div className="form_man_wrp">
                      <div className="_head">
                        <h4>Verify Host</h4>
                      </div>
                      <form onSubmit={handleSubmit}>
                        <div className="add_form">
                          <div className="add_form_wrap">
                            <div className="_item">
                              <div>
                                <p>Verify</p>
                              </div>
                              <label>
                                <input
                                  type="radio"
                                  checked={verify === "approved"}
                                  name="approved"
                                  value="approved"
                                  onChange={handleListingChange}
                                />
                              </label>
                            </div>
                            <div className="_item">
                              <div>
                                <p>Unverify</p>
                              </div>
                              <label>
                                <input
                                  type="radio"
                                  disabled
                                  checked={verify === "pending"}
                                  name="pending"
                                  value="pending"
                                  onChange={handleListingChange}
                                />
                              </label>
                            </div>
                            <div className="save">
                              <button className="_btn">
                                <span> Verify</span>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="close">
                          <span onClick={handleAddNew}>
                            <IoIosCloseCircleOutline size={22} />
                          </span>
                        </div>
                      </form>
                    </div>
                    <div className="_head">
                      <h4>Images</h4>
                    </div>

                    <div className="img_wrp">
                      {pendingListing.photos.map((img) => (
                        <div className="img_wrp_con">
                          <img
                            src={`${process.env.REACT_APP_IMAGE_UPLOAD_LINK}/${img}`}
                            alt=""
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="listing_list">
            {loading ? (
              "Loading..."
            ) : (
              <DataGrid
                key={data.listingId}
                onCellClick={onEditClick}
                rows={data}
                columns={columns}
                pageSize={5}
                disableRowSelectionOnClick
                initialState={{
                  pagination: { paginationModel: { pageSize: 5 } },
                }}
                getRowId={(row) => row.listingId}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pending;
