import React, { useContext } from "react";
import "./hostNav.scss";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

const HostNav = () => {
  const { user } = useContext(AuthContext);

  const id = user.id;

  return (
    <div className="host_nav_con">
      <div className="host_nav">
        <ul>
          <Link to={`/today-bookings`}>
            <li> Today Booking </li>
          </Link>
          <Link to={`/admin-calender`}>
            <li>Calendar </li>
          </Link>
          <Link to={`/all-hosts/${id}`}>
            <li> All Hosts</li>
          </Link>
          <Link to={`/pending-listing/${id}`}>
            <li> Pending</li>
          </Link>
          <Link to={`/approved-listing/${id}`}>
            <li> Approved</li>
          </Link>
          <Link to={`/all-guests/${id}`}>
            <li> All Guests</li>
          </Link>
          <Link to={`/flight-info/${id}`}>
            <li> Flights Inquiry</li>
          </Link>
          <Link to={`/airport-taxi-info/${id}`}>
            <li> AirPort Taxi Inquiry</li>
          </Link>
          <Link to={`/tour-inquiry`}>
            <li>Tours Inquiry </li>
          </Link>
          <Link to={`/all-tour`}>
            <li>Tours</li>
          </Link>
          <Link to={`/all-blog`}>
            <li>Blog</li>
          </Link>
        </ul>
      </div>
    </div>
  );
};

export default HostNav;
