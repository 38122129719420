import React, { useContext } from "react";
import "./allGuests.scss";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { AuthContext } from "../../context/AuthContext";
import HostNav from "../../components/hostNav/HostNav";
import useFetch from "../../hooks/useFetch";

const ToureInq = () => {

      const { user } = useContext(AuthContext);
      const id = user.id;
      const { data, loading, error, reFetch } = useFetch(`/get_tour_inq`);


       const columns = [
         {
           field: "tour_name",
           headerName: "Tour",
           width: "200",
         },
         { field: "name", headerName: "Guset Name", width: "200" },
         { field: "email", headerName: "Email", width: "200" },
         { field: "phone", headerName: "Phone", width: "200" },
         { field: "pax", headerName: "Pax", width: "200" },
         { field: "date", headerName: "date", width: "200" },
         { field: "message", headerName: "Message", width: "200" },
         { field: "know", headerName: "Source", width: "200" },
       ];



  return (
    <div className="__fw" style={{ minHeight: "100dvh" }}>
      <div className="__mw">
        <HostNav />
        <div className="admin_listings">
          <div className="admin_listings_head">
            <div
              className="listings_item_text"
              style={{ marginBottom: "20px" }}
            >
              <h2>Tour Inquiry</h2>
            </div>
            <div></div>
          </div>

          <div className="listing_list">
            <DataGrid
              key={data._id}
              // onCellClick={onEditClick}
              rows={data}
              columns={columns}
              pageSize={5}
              over
              disableRowSelectionOnClick
              initialState={{
                pagination: {
                  paginationModel: {
                    //pageSize:
                  },
                },
              }}
              getRowId={(row) => row._id}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )

};

export default ToureInq;
