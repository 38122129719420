import React, { useContext, useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { MdOutlineEdit } from "react-icons/md";
import { IoIosLock } from "react-icons/io";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import { AuthContext } from "../../context/AuthContext";
import HostNav from "../../components/hostNav/HostNav";
import { useMemo } from "react";

const Approved = () => {
  const { user } = useContext(AuthContext);
  const [verify, setVerify] = useState("");
  const [showAddNew, setShowAddNew] = useState(false);
  const [showVarifyNew, setShowVarifyNew] = useState(false);
  const [itemEditId, setItemEditId] = useState("");
  const [ownerId, setOwnerId] = useState("");
  const [response, setResponse] = useState("");
  const [percentage, setPercentage] = useState();
    const [type, setType] = useState("");
  const [hostPrice, setHostPrice] = useState();
  // const [salePrice, setSalePrice] = useState(0);
  const [approveListing, setApproveListing] = useState(null);

  const [certify, setCertify] = useState("");
  const navigate = useNavigate();
  const id = user.id;
  const { data, loading, error, reFetch } = useFetch(`/getAllAprovedListing`);

  // console.log(data);

   const handleTypeChange = (event) => {
     setType(event.target.value);
   };


  const onEditClick = (params) => {
    const getItem = params.row;
    setItemEditId(getItem.listingId);
    setOwnerId(getItem.owner);
    setShowVarifyNew(true);
    setApproveListing(getItem);
  };
  // console.log(itemEditId, ownerId);

  // ! select Listing
  const handleListingChange = (event) => {
    setVerify(event.target.value);
  };

  const handleVarify = () => {
    setShowVarifyNew(!showVarifyNew);
  };

  useEffect(() => {
    reFetch();
  }, [response]);

  const handleSave = async (e) => {
    e.preventDefault();
    const listData = {
      verify,
      itemEditId,
      ownerId,
    };
    try {
      await axios
        .put(`/verifyListing/${itemEditId}/${ownerId}`, listData)
        .then((response) => {
          setResponse(response.data);
        });
      setShowVarifyNew("");
      setVerify("");
      navigate("/today-bookings");
    } catch (error) {
      console.log(error);
    }
  };

  const calculateSalePrice = () => {
    const calPrice = (approveListing?.price * percentage) / 100;
    const totalCal = calPrice + approveListing?.price;
    return totalCal;
  };

  const salePrice = calculateSalePrice();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const listData = {
      percentage,
      itemEditId,
      ownerId,
      salePrice,
    };


    if (!salePrice) {
      return;
    } else {
      try {
        await axios
          .put(`/planUpdateHost/${itemEditId}/${ownerId}`, listData)
          .then((response) => {
            setResponse(response.data);
          });
        setShowVarifyNew("");
        setPercentage("");
        navigate("/today-bookings");
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleTypeSubmit = async (e) => {
    e.preventDefault();
    const typeData = {
      type,
      itemEditId,
      ownerId,
    };
    try {
      const response = await axios.put(
        `/typeListing/${itemEditId}/${ownerId}`,
        typeData
      );
      setResponse(response.data);
      setShowAddNew(false);
      setItemEditId("");
      setType("");
      navigate("/today-bookings");
    } catch (error) {
      console.error(error);
    }
  };

  // ! grid

  const columns = useMemo(() => [
    { field: "listingId", headerName: "Listing ID", width: 250 },
    { field: "fName", headerName: "Name" },
    { field: "city", headerName: "City" },
    {
      field: "status",
      headerName: "Status",
    },
    {
      field: "type",
      headerName: "Type",
    },
    {
      field: "percentage",
      headerName: "Guest %",
    },
    {
      field: "price",
      headerName: "Host Price",
    },
    {
      field: "salePrice",
      headerName: "Sale Price",
    },
    {
      field: "owner",
      headerName: "Owner ID",
    },
    
  ],[
    { field: "listingId", headerName: "Listing ID", width: 250 },
    { field: "fName", headerName: "Name" },
    { field: "city", headerName: "City" },
    {
      field: "status",
      headerName: "Status",
    },
    {
      field: "type",
      headerName: "Type",
    },
    {
      field: "percentage",
      headerName: "Guest %",
    },
    {
      field: "price",
      headerName: "Host Price",
    },
    {
      field: "salePrice",
      headerName: "Sale Price",
    },
    {
      field: "owner",
      headerName: "Owner ID",
    },
  ],[]);



  return (
    <div className="__fw" style={{ minHeight: "100dvh" }}>
      <div className="__mw">
        <HostNav />
        <div className="admin_listings">
          <div className="admin_listings_head">
            <div
              className="listings_item_text"
              style={{ marginBottom: "20px" }}
            >
              <h2>Approved listing</h2>;
            </div>
            <div></div>
          </div>

          <div className="addNew">
            {showVarifyNew && (
              <div className="addNew_in">
                <div className="addNew_wrap">
                  <div className="profile_wrp">
                    <div className="p_wrp_hd">
                      <h3>{approveListing.title}</h3>
                      <small>
                        {approveListing.fName} {approveListing.lName}
                      </small>
                      <small
                        style={{
                          margin: "5px 0px",
                          color: "green",
                          fontWeight: "600",
                        }}
                      >
                        {approveListing.status}
                      </small>
                      <hr />
                      <div className="wrp_in">
                        <small>Owner ID</small>
                        <span>{approveListing.owner}</span>
                      </div>
                      <div className="wrp_in">
                        <small>Listing ID</small>
                        <span>{approveListing.listingId}</span>
                      </div>
                      <div className="mn_wrp_in">
                        <div className="wrp_in">
                          <small>Price</small>
                          <span>Rs. {approveListing.price}</span>
                        </div>
                        <div className="wrp_in">
                          <small>Customer</small>
                          <span>{approveListing.percentage}%</span>
                        </div>
                        <div className="wrp_in">
                          <small>Sale Price</small>
                          <span>Rs. {approveListing.salePrice}</span>
                        </div>
                      </div>
                      <hr />
                      <div className="wrp_in">
                        <small>Category</small>
                        <span>{approveListing.selectHomeCategory}</span>
                      </div>
                      <div className="wrp_in">
                        <small>Type of Place</small>
                        <span>{approveListing.selectPlace}</span>
                      </div>
                      <div className="wrp_in">
                        <small>Address</small>
                        <span>
                          {approveListing.province}, {approveListing.city},{" "}
                          {approveListing.address}, {approveListing.country}
                        </span>
                      </div>
                      <hr />
                      <div className="wrp_in">
                        <small>Type of Accommodation</small>
                        <span>{approveListing.type}</span>
                      </div>
                      <hr />
                      <div className="mn_wrp_in">
                        <div className="wrp_in">
                          <small>Bedrooms</small>
                          <span>{approveListing.beadrooms}</span>
                        </div>
                        <div className="wrp_in">
                          <small>Bathrooms</small>
                          <span>{approveListing.bathrooms}</span>
                        </div>
                        <div className="wrp_in">
                          <small>Beds</small>
                          <span>{approveListing.beds}</span>
                        </div>
                        <div className="wrp_in">
                          <small>Adults</small>
                          <span>{approveListing.adults}</span>
                        </div>
                        <div className="wrp_in">
                          <small>Children</small>
                          <span>{approveListing.children}</span>
                        </div>
                        <div className="wrp_in">
                          <small>Infants</small>
                          <span>{approveListing.infants}</span>
                        </div>
                        <div className="wrp_in">
                          <small>Share Bathrooms</small>
                          <span>{approveListing.shareBathrooms}</span>
                        </div>
                      </div>
                      <hr />
                      <div className="mn_wrp_in_two">
                        <div className="wrp_in">
                          <small>CheckIn Time</small>
                          <span>{approveListing.checkIn}</span>
                        </div>
                        <div className="wrp_in">
                          <small>CheckOut Time</small>
                          <span>{approveListing.checkOut}</span>
                        </div>
                      </div>
                      <hr />
                      <div className="mn_wrp_in">
                        <div className="wrp_in">
                          <small>Itinerary</small>
                          <div>
                            {approveListing.itinerary.map((item) => (
                              <span>{item}</span>
                            ))}
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="wrp_in">
                        <small>Description</small>
                        <span className="span">
                          {approveListing.description}
                        </span>
                      </div>
                      <div className="wrp_in">
                        <small>Extra Info</small>
                        <span className="span">{approveListing.extraInfo}</span>
                      </div>
                      <div className="wrp_in">
                        <small>House Rule</small>
                        <span className="span">{approveListing.houseRule}</span>
                      </div>
                    </div>
                  </div>
                  <div className="info_wrp">
                    <div className="form_wrp">
                      <div className="form_man_wrp">
                        <div className="_head">
                          <h4>Verify Host</h4>
                        </div>
                        <form onSubmit={handleSave}>
                          <div className="add_form">
                            <div className="add_form_wrap">
                              <div className="_item">
                                <div>
                                  <p>Verify</p>
                                </div>
                                <label>
                                  <input
                                    disabled
                                    type="radio"
                                    checked={verify === "approved"}
                                    name="approved"
                                    value={"approved"}
                                    onChange={handleListingChange}
                                  />
                                </label>
                              </div>
                              <div className="_item">
                                <div>
                                  <p>Unverify</p>
                                </div>
                                <label>
                                  <input
                                    type="radio"
                                    checked={verify === "pending"}
                                    name="pending"
                                    value={"pending"}
                                    onChange={handleListingChange}
                                  />
                                </label>
                              </div>
                              <div className="save">
                                <button className="_btn">
                                  <span>Save</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>

                      <div className="form_man_wrp">
                        <div className="_head">
                          <h4>Verify Host</h4>
                        </div>
                        <form onSubmit={handleSubmit}>
                          <div className="add_form">
                            <div className="add_form_wrap">
                              <div className="_item">
                                <div>
                                  <p>Guest %</p>
                                </div>
                                <input
                                  type="number"
                                  name="pending"
                                  value={percentage}
                                  onChange={(e) =>
                                    setPercentage(e.target.value)
                                  }
                                />
                              </div>
                              <div className="save">
                                <button className="_btn">
                                  <span>Save</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>

                      <div className="form_man_wrp">
                        <div className="_head">
                          <h4>Type</h4>
                        </div>

                        <form onSubmit={handleTypeSubmit}>
                          <div className="add_form">
                            <div className="add_form_wrap">
                              <div className="_item">
                                <div>
                                  <p>Normal</p>
                                </div>
                                <label>
                                  <input
                                    type="radio"
                                    checked={type === "normal"}
                                    name="normal"
                                    value="normal"
                                    onChange={handleTypeChange}
                                  />
                                </label>
                              </div>
                              <div className="_item">
                                <div>
                                  <p>Featured</p>
                                </div>
                                <label>
                                  <input
                                    type="radio"
                                    checked={type === "featured"}
                                    name="featured"
                                    value="featured"
                                    onChange={handleTypeChange}
                                  />
                                </label>
                              </div>
                              <div className="_item">
                                <div>
                                  <p>Trending</p>
                                </div>
                                <label>
                                  <input
                                    type="radio"
                                    checked={type === "trending"}
                                    name="trending"
                                    value="trending"
                                    onChange={handleTypeChange}
                                  />
                                </label>
                              </div>
                              <div className="save">
                                <button className="_btn">
                                  <span> Update</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>

                      <div className="_head">
                        <h3>Images</h3>
                      </div>

                      <div className="img_wrp">
                        {approveListing.photos.map((img) => (
                          <div className="img_wrp_con">
                            <img
                              src={`${process.env.REACT_APP_IMAGE_UPLOAD_LINK}/${img}`}
                              alt=""
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="close">
                    <span onClick={handleVarify}>
                      <IoIosCloseCircleOutline size={22} />
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>

          {loading ? (
            "Loading..."
          ) : (
            <div className="listing_list">
              <DataGrid
                loading={loading}
                key={data.listingId}
                onCellClick={onEditClick}
                rows={data}
                columns={columns}
                pageSize={5}
                over
                disableRowSelectionOnClick
                initialState={{
                  pagination: {
                    paginationModel: {
                      // pageSize:{5}
                    },
                  },
                }}
                getRowId={(row) => row.listingId}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Approved;
