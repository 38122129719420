import React, { useContext, useEffect, useRef, useState } from "react";
import "./todayBooking.scss";
import ProgressBar from "../../components/pograssBar/ProgressBar";
import { differenceInCalendarDays, format } from "date-fns";
import axios from "axios";
import { FaCircleUser } from "react-icons/fa6";
import useFetch from "../../hooks/useFetch";
import HostNav from "../../components/hostNav/HostNav";
import { AuthContext } from "../../context/AuthContext";


const appStyles = {
  height: 10,
  width: 400,
  margin: 10,
};

const TodayBooking = () => {
  const { user } = useContext(AuthContext);
  const [guestBooking, setGuestBooking] = useState([]);
  const [progress, setProgress] = useState(0);
  const name = user.fName + " " + user.lName;
  const ownerId = user.id;
  const id = user.id;
  const [message, setMessage] = useState("");
  const [bookingId, setBookingId] = useState("");
  const [userId, setUserId] = useState("");

  const { data, loading, error, reFetch } = useFetch(`/getAllBooking`);

 useEffect(() => {
   const cancelToken = axios.CancelToken.source();
   const fetchData = async () => {
     try {
       const response = await axios.get(`/getAllBooking`, {
         cancelToken: cancelToken.token,
       });
       const guestList = response.data;
       const bookingPlace = guestList;
       setGuestBooking(bookingPlace);
     } catch (error) {
       if (axios.isCancel(error)) {
         console.log("Request canceled", error.message);
       } else {
         console.error("Error fetching data:", error);
       }
     }
   };
   fetchData(); 
   return () => {
     cancelToken.cancel();
   };
 }, []);

 const messageBodyRef = useRef(null);

 const getIds = (bookingId, userId) => {
   setBookingId(bookingId);
   setUserId(userId);
 };

  // console.log(guestBooking);

  const sendMessage = async (e) => {
    e.preventDefault();
    const list = {
      message,
      guestName: data.fName,
    };
    try {
      await axios.post(`sendMessage/`, list);
    } catch (error) {
      console.log(error);
    }
    setMessage("");
  };

  const checkInDate =
    guestBooking.length > 0 && guestBooking.checkIn
      ? new Date(guestBooking.checkIn)
      : null;

  const checkOutDate =
    guestBooking.length > 0 && guestBooking.checkOut
      ? new Date(guestBooking.checkOut)
      : null;

  const dateDef = () => {
    const daysDifference = differenceInCalendarDays(checkOutDate, checkInDate);
    return daysDifference;
  };

  const daysDifference = dateDef();
  // console.log(daysDifference);

  return (
    <div className="__fw" style={{ minHeight: "100dvh" }}>
      <div className="__mw">
        <HostNav />
        <div className="admin_listings">
      
          <div className="admin_listings_head">
            <div
              className="listings_item_text"
              style={{ marginBottom: "20px" }}
            >
              {" "}
              <span>{user.role}</span>
              <h2>
                Welcome, <em style={{fontStyle:"normal",color:"orange"}} >{name}</em>{" "}
              </h2>
            </div>
            <div></div>
          </div>

          <div className="reservations">
            <h2>Your reservations</h2>
            <div className="reservation_info">
              {guestBooking.length > 0 &&
                guestBooking.map((item) => (
                  <>
                    <div className="_bookig_card">
                      <div key={item._id}>
                        {/* <div className="img">
                          <div className="img_host">
                            <img
                              src={`${process.env.REACT_APP_IMAGE_UPLOAD_LINK}/${item.placeImg}`}
                              alt=""
                            />
                          </div>
                        </div> */}
                        <div className="info">
                          <div className="info_in">
                            <strong>{item.name}</strong>

                            <span>{item.tempEmail}</span>

                            <em>{item.country}</em>
                            <p>{item.placeName}</p>
                          </div>
                        </div>
                        <div className="data">
                          <div className="check">
                            <span>Check-In</span>
                            <small>
                              {new Date(item.checkIn).toLocaleDateString()}
                            </small>
                          </div>
                          <div className="pograss">
                            <div className="days">
                              <span>
                                {differenceInCalendarDays(
                                  item.checkOut,
                                  item.checkIn
                                )}{" "}
                                - Nights
                              </span>
                            </div>
                            <div className="pograss_bar" style={appStyles}>
                              <ProgressBar
                                bgColor={"var(--blue)"}
                                progress={progress}
                              />
                            </div>
                          </div>
                          <div className="check">
                            <span>Check-Out</span>
                            <small>
                              {new Date(item.checkOut).toLocaleDateString()}
                            </small>
                          </div>
                        </div>
                        <div className="price">
                          <div className="price_in">
                            <em>Adults</em>
                            <div className="strong">
                              <strong>{item.adults}</strong>
                            </div>
                          </div>
                          <div className="price_in">
                            <em>Children</em>
                            <div className="strong">
                              <strong>{item.children}</strong>
                            </div>
                          </div>
                          <div className="price_in">
                            <em>Infants</em>
                            <div className="strong">
                              <strong>{item.infants}</strong>
                            </div>
                          </div>
                          <div className="price_in">
                            <em>Rs </em>
                            <div className="strong">
                              <h3> {item.price?.toFixed(2)} </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="booking_form">
                        <div className="message_body" ref={messageBodyRef}>
                          {item.messages.map((msg, index) => (
                            <div key={index} className="masg_wrap">
                              <div className="msg_icon">
                                <FaCircleUser />
                                <strong>{msg.name}</strong>
                              </div>
                              <div className="em">
                                <em>Subject: {item.selectOption}</em>
                              </div>
                              <small>{msg.message}</small> <br />
                              <span>
                                {new Date(msg.createdTime).toLocaleString()}
                              </span>
                            </div>
                          ))}
                        </div>
                        <form onSubmit={sendMessage}>
                          <div className="booking_form_input">
                            <textarea
                              type="text"
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                              onClick={() =>
                                getIds(
                                  item.bookingId,
                                  item.messages.find((item) => item.userId)
                                    ?.userId
                                )
                              }
                            />
                          </div>

                          <div className="send_btn">
                            <button className="__btn_">Send</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TodayBooking;
