import React from "react";
import "./App.css";
import axios from "axios";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import AllHosts from "./pages/allHostList/AllHosts";
import Navbar from "./components/navBar/Navbar";
import AllGuests from "./pages/allGuests/AllGuests";
import FlightInfo from "./pages/allGuests/FlightInfo";
import AirPortTaxiInfo from "./pages/allGuests/AirPortTaxiInfo";
import TodayBooking from "./pages/todayBooking/TodayBooking";
import AdminCalender from "./pages/adminCalender/AdminCalender";

import Footer from "./components/footer/Footer";
import { ProtectedRoute } from "./util/ProtectedRoute";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import Error from "./pages/error/Error";
import Pending from "./pages/allHostList/Pending";
import Approved from "./pages/allHostList/Approved";
import AddTour from "./pages/addTour/AddTour";
import AllTour from "./pages/addTour/AllTour";
import ToureInq from "./pages/allGuests/ToureInq";
import AddBlog from "./pages/addTour/AddBlog";
import AllBlog from "./pages/addTour/AllBlog";
;

// axios.defaults.baseURL = "http://localhost:5000/api/v1";
axios.defaults.baseURL = "https://api.leisurery.com/api/v1";
// axios.defaults.baseURL = "https://api.leisurery.com/api/v1";
axios.defaults.withCredentials = true;

const App = () => {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
};

const AppContent = () => {
  const location = useLocation();

  const isLoginOrRegister =
    location.pathname === "/" ||
    location.pathname === "/register" ||
    location.pathname.startsWith("/about-host/");

  return (
    <>
      {!isLoginOrRegister && <Navbar />}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Register />} />

        <Route
          path="/pending-listing/:id"
          element={
            <ProtectedRoute>
              <Pending />
            </ProtectedRoute>
          }
        />

        <Route
          path="/approved-listing/:id"
          element={
            <ProtectedRoute>
              <Approved />
            </ProtectedRoute>
          }
        />
        <Route
          path="/all-hosts/:id"
          element={
            <ProtectedRoute>
              <AllHosts />
            </ProtectedRoute>
          }
        />
        <Route
          path="/all-guests/:id"
          element={
            <ProtectedRoute>
              <AllGuests />
            </ProtectedRoute>
          }
        />
        <Route
          path="/flight-info/:id"
          element={
            <ProtectedRoute>
              <FlightInfo />
            </ProtectedRoute>
          }
        />
        <Route
          path="/airport-taxi-info/:id"
          element={
            <ProtectedRoute>
              <AirPortTaxiInfo />
            </ProtectedRoute>
          }
        />
        <Route
          path="/today-bookings"
          element={
            <ProtectedRoute>
              <TodayBooking />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-calender"
          element={
            <ProtectedRoute>
              <AdminCalender />
            </ProtectedRoute>
          }
        />

        <Route
          path="/all-tour"
          element={
            <ProtectedRoute>
              <AllTour />
            </ProtectedRoute>
          }
        />
        <Route
          path="/tour-inquiry"
          element={
            <ProtectedRoute>
              <ToureInq />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-tour"
          element={
            <ProtectedRoute>
              <AddTour />
            </ProtectedRoute>
          }
        />
        <Route
          path="/all-blog"
          element={
            <ProtectedRoute>
              <AllBlog />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-blog"
          element={
            <ProtectedRoute>
              <AddBlog />
            </ProtectedRoute>
          }
        />
        <Route
          path="/update-tour/:id"
          element={
            <ProtectedRoute>
              <AddTour />
            </ProtectedRoute>
          }
        />
        <Route
          path="/update-blog/:id"
          element={
            <ProtectedRoute>
              <AddBlog />
            </ProtectedRoute>
          }
        />

        <Route path="*" element={<Error />} />
      </Routes>
      {/* {!isLoginOrRegister && <Footer />} */}
    </>
  );
};

export default App;
