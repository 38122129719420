import React, { useContext, useEffect, useState } from "react";
import "./allHost.scss";
import { Link, Navigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import useFetch from "../../hooks/useFetch";
import axios from "axios";
import { MdOutlineEdit } from "react-icons/md";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { AiFillSafetyCertificate } from "react-icons/ai";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { AuthContext } from "../../context/AuthContext";
import HostNav from "../../components/hostNav/HostNav";

const AllHosts = () => {
  const { user } = useContext(AuthContext);
  const [verify, setVerify] = useState("");
  const [showAddNew, setShowAddNew] = useState(false);
  const [itemEditId, setItemEditId] = useState("");
  const [title, setTitle] = useState("");
  const [response, setResponse] = useState("");
  const [showVarifyNew, setShowVarifyNew] = useState(false);
  const [certify, setCertify] = useState("");
    const [plan, setPlan] = useState("");
      const [selectedHost, setSelectedHost] = useState(null);
  const id = user.id;

  const onEditClick = (params) => {
    const hostData = data.find((host) => host._id === params.id);
    setSelectedHost(hostData);
    setShowAddNew(true);
  };


  const onVarifyClick = (id) => {
    const data = id;
    setItemEditId(data);
    setShowVarifyNew(!showVarifyNew);
  };


  const { data, loading, error, reFetch } = useFetch(`/getAllHost`);


  // ! select Listing
  const handleListingChange = (event) => {
    setVerify(event.target.value);
  };

   const handlePlanChange = (event) => {
     setPlan(event.target.value);
   };

  // ! seletct certify
  const handleSelectCertify = (event) => {
    setCertify(event.target.value);
  };

  const handleAddNew = () => {
    setShowAddNew(!showAddNew);
  };

  const handleCertifyClose = () => {
    setShowVarifyNew(!showVarifyNew);
  };

  useEffect(() => {
    reFetch();
  }, [response]);

  const handleCertifyHost = async (e) => {
    e.preventDefault();
    const listData = {
      certify,
      itemEditId,
    };
    try {
      await axios.put("/certifyHost", listData).then((response) => {
        setResponse(response.data);
      });
      setShowAddNew("");
      setCertify("")
      Navigate(`/all-hosts/${id}`);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      verify,
      itemEditId
    };
    try {
      await axios.put("/verifyHost", data).then((response) => {
        setResponse(response.data);
      });
      setShowAddNew("");
      setItemEditId("");
      setVerify("");
      Navigate(`/all-hosts/${id}`);
    } catch (error) {
      console.log(error);
    }
  };

   const handleUpdatePlan = async (e) => {
     e.preventDefault();
     const listData = {
      plan,

     };
     try {
       await axios
         .put(`/planUpdatePlan/${itemEditId}`, listData)
         .then((response) => {
           setResponse(response.data);
         });
         setShowAddNew("");
     } catch (error) {
       console.log(error);
     }
   };

  // ! grid

  const columns = [
    { field: "fName", headerName: "Name", width: 200 },
    { field: "email", headerName: "Email" },
    { field: "plan", headerName: "Plan" },
    { field: "address", headerName: "Address", width: 250 },
    {
      field: "phone",
      headerName: "Contact",
    },

    {
      field: "certify",
      headerName: "Certify",
    },
    { field: "isVerified", headerName: "Status" },
  ];

  return (
    <div className="__fw" style={{ minHeight: "100dvh" }}>
      <div className="__mw">
        <HostNav />
        <div className="admin_listings">
          <div className="admin_listings_head">
            <div
              className="listings_item_text"
              style={{ marginBottom: "20px" }}
            >
              <h2>All Hosts</h2>
            </div>
            <div></div>
          </div>

          <div className="table">
            <div className="addNew">
              {showAddNew && selectedHost && (
                <div className="addNew_in">
                  <div className="addNew_wrap">
                    <div className="profile_wrp">
                      <div className="p_wrp_hd">
                        <h3>
                          {selectedHost.fName}
                          {selectedHost.lName}({selectedHost.displayName})
                        </h3>
                        <hr />

                        <div className="wrp_in">
                          <small>Email</small>
                          <span>{selectedHost.email}</span>
                        </div>

                        <div className="wrp_in">
                          <small>Mobile</small>
                          <span>{selectedHost.phone}</span>
                        </div>

                        <div className="wrp_in">
                          <small>Address</small>
                          <span>{selectedHost.address}</span>
                        </div>
                        <hr />

                        <div className="wrp_in">
                          <small>Verified</small>
                          <span>{selectedHost.isVerified}</span>
                        </div>

                        <div className="wrp_in">
                          <small>Plan</small>
                          <span>{selectedHost.plan}</span>
                        </div>

                        <div className="wrp_in">
                          <small>Certify</small>
                          <span>{selectedHost.certify}</span>
                        </div>
                        <hr />
                        <div className="wrp_in">
                          <small>Text One</small>
                          <span>{selectedHost.headOne}</span>
                        </div>

                        <div className="wrp_in">
                          <small>Text Two</small>
                          <span>{selectedHost.headTwo}</span>
                        </div>

                        <div className="wrp_in">
                          <small>Text Three</small>
                          <span>{selectedHost.headThree}</span>
                        </div>
                      </div>
                    </div>

                    <div className="form_wrp">
                      <div className="form_man_wrp">
                        <form onSubmit={handleSubmit}>
                          <div className="add_form">
                            <div className="_head">
                              <h4>Verify Host</h4>
                            </div>
                            <div className="add_form_wrap">
                              <div className="_item">
                                <div>
                                  <p>Verify</p>
                                </div>
                                <label>
                                  <input
                                    type="radio"
                                    checked={verify === "verify"}
                                    name="verify"
                                    value={"verify"}
                                    onChange={handleListingChange}
                                  />
                                </label>
                              </div>
                              <div className="_item">
                                <div>
                                  <p>Unverify</p>
                                </div>
                                <label>
                                  <input
                                    type="radio"
                                    checked={verify === "unverify"}
                                    name="unverify"
                                    value={"unverify"}
                                    onChange={handleListingChange}
                                  />
                                </label>
                              </div>
                              <div className="save">
                                <button className="_btn">
                                  <span> Verify</span>
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="close">
                            <span onClick={handleAddNew}>
                              <IoIosCloseCircleOutline size={22} />
                            </span>
                          </div>
                        </form>
                      </div>

                      <div className="form_man_wrp">
                        <form onSubmit={handleCertifyHost}>
                          <div className="add_form">
                            <div className="add_form_head">
                              <h4>Certify Host </h4>{" "}
                              <AiFillSafetyCertificate
                                size={20}
                                color="var(--green)"
                              />
                            </div>
                            <br />
                            <div className="add_form_wrap">
                              <div className="_item">
                                <div>
                                  <p>Certify</p>
                                </div>
                                <label>
                                  <input
                                    type="radio"
                                    checked={certify === "certify"}
                                    name="certify"
                                    value={"certify"}
                                    onChange={handleSelectCertify}
                                  />
                                </label>
                              </div>
                              <div className="_item">
                                <div>
                                  <p>Uncertify</p>
                                </div>
                                <label>
                                  <input
                                    type="radio"
                                    checked={certify === "unverify"}
                                    name="uncertify"
                                    value={"uncertify"}
                                    onChange={handleSelectCertify}
                                  />
                                </label>
                              </div>
                              <div className="save">
                                <button className="_btn">
                                  <span> Certify</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>

                      <div className="form_man_wrp">
                        <form onSubmit={handleUpdatePlan}>
                          <div className="add_form">
                            <div className="add_form_head">
                              <h4>Host's Plan</h4>
                            </div>
                            <br />
                            <div className="add_form_wrap_sub">
                              <div className="_item">
                                <div>
                                  <p>Free</p>
                                </div>
                                <label>
                                  <input
                                    type="radio"
                                    checked={plan === "free"}
                                    name="free"
                                    value={"free"}
                                    onChange={handlePlanChange}
                                  />
                                </label>
                              </div>
                              <div className="_item">
                                <div>
                                  <p>Gold</p>
                                </div>
                                <label>
                                  <input
                                    type="radio"
                                    checked={plan === "gold"}
                                    name="gold"
                                    value={"gold"}
                                    onChange={handlePlanChange}
                                  />
                                </label>
                              </div>
                              <div className="_item">
                                <div>
                                  <p>Platinum</p>
                                </div>
                                <input
                                  type="radio"
                                  checked={plan === "platinum"}
                                  name="platinum"
                                  value={"platinum"}
                                  onChange={handlePlanChange}
                                />
                              </div>
                              <div className="save">
                                <button className="_btn">
                                  <span> Update</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="listing_list">
            <DataGrid
              key={data._id}
              onCellClick={onEditClick}
              rows={data}
              columns={columns}
              pageSize={5}
              over
              disableRowSelectionOnClick
              initialState={{
                pagination: {
                  paginationModel: {
                    //pageSize:
                  },
                },
              }}
              getRowId={(row) => row._id}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllHosts;
