import React, { useContext } from "react";
import "./navbar.scss";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import HotelIcon from "@mui/icons-material/Hotel";
import ParkIcon from "@mui/icons-material/Park";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";


import { MdOutlineFlight } from "react-icons/md";

const Navbar = () => {
  const navigate = useNavigate();
  const { user, dispatch } = useContext(AuthContext);

  const firstName = user ? user.fName?.charAt(0) : "";
  const role = user ? user.role : "";



  const logoutUser = async () => {
    const controller = new AbortController();
    const signal = controller.signal;
    try {
      await axios.get("/logout");
      localStorage.removeItem("user");
      dispatch({ type: "LOGOUT" });
      navigate("/");
    } catch (error) {
       if (axios.isCancel(error)) {
         console.log("Request canceled", error.message);
       } else {
         console.error("Error fetching data:", error);
       }
    }
    return () => controller.abort();
  };
  
  // Define routes based on user role
  const getProfileRoute = () => {
    if(role === "host"){
      return "/hosting";
    }else if(role ===  "admin") {
      return "/today-bookings"
    } else if (role === "guest") {
      return "/user-profile";
    } else {
      return "/login";
    }
  };

  return (
    <div className="_nfw " style={{ backgroundColor: "#0068cf" }}>
      <div className="_nmw ">
        <section>
          <div className="nav-sec">
            <Link to={"/"}>
              <div className="__n_item">
                <div className="_logo">
                  <img src={require("../../img/logo.png")} alt="" />
                  {/* <h1>
                    Leisurery<em>.com</em>
                  </h1> */}
                </div>
              </div>
            </Link>
          </div>
          <div className="headerList">
            {/* <Link className="nav_link" to={"/"}>
              <div className="headerListItem active">
                <HotelIcon fontSize="20px" />
                <span>Stays</span>
              </div>
            </Link>

            <Link className="nav_link">
              <div className="headerListItem">
                <ParkIcon fontSize="20px" />
                <span>Attraction</span>
              </div>
            </Link>

            <Link className="nav_link">
              <div className="headerListItem">
                <FastfoodIcon fontSize="20px" />
                <span>Dining</span>
              </div>
            </Link>
            <Link className="nav_link" to={"/airport-taxi"}>
              <div className="headerListItem">
                <DirectionsCarIcon fontSize="20px" />
                <span>Airport Taxi</span>
              </div>
            </Link>

            <Link className="nav_link" to={"/flights"}>
              <div className="headerListItem">
                <MdOutlineFlight
                  size={20}
                  style={{ transform: "rotate(35deg)" }}
                />
                <span>Flight</span>
              </div>
            </Link> */}
          </div>

          {!(
            user?.role === "host" ||
            user?.role === "guest" ||
            user?.role === "admin"
          ) && (
            <>
              <div className="nav-sec">
                <div className="nav-sec-pro">
                  <Link to={"/list-your-property"}>
                    <div className="list_btn">
                      <span>List your property</span>
                    </div>
                  </Link>
                  <div className="_icon">
                    <span className="indicator">
                      <span className="indicate">&nbsp;</span>
                      <span className="indicate-border">&nbsp;</span>
                      <span className="indicate-border indicate-border-1">
                        &nbsp;
                      </span>
                      <span className="indicate-border indicate-border-2">
                        &nbsp;
                      </span>
                      <span className="indicate-border indicate-border-3">
                        &nbsp;
                      </span>
                      <span className="indicate-border indicate-border-4">
                        &nbsp;
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="nav-sec">
            <Link to={getProfileRoute()}>
              <div className="__n_login">
                {user ? (
                  <div className="__profile_login">
                    <span onClick={logoutUser}>Logout</span>
                    <div className="__profile">
                      <div className="pro_name">{firstName}</div>
                      {/* <div className="__profile">
                        <PersonIcon fontSize="20" />
                      </div> */}
                    </div>
                  </div>
                ) : (
                  <div className="_profile">
                    <Link to={"/register"}>
                      <span>Sing Up</span>
                    </Link>
                    <span style={{ color: "#222222" }}>|</span>
                    <span onClick={logoutUser}>Login</span>
                  </div>
                )}
              </div>
            </Link>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Navbar;
