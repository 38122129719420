import React, { useEffect, useState } from "react";
import "./addTour.scss";
import taxi from "../../img/taxi-solid.png";
import eye from "../../img/eye-solid.png";
import map from "../../img/map-marker-alt-solid.png";
import user from "../../img/user-solid.png";
import bed from "../../img/bed-solid.png";
import Tailormade from "../../img/Tailormade.png";
import sigiriya from "../../img/sqr_banner_sigiriya.jpg";
import axios from "axios";
import HostNav from "../../components/hostNav/HostNav";
import { MdAdd } from "react-icons/md";
import { Link, useParams } from "react-router-dom";

const AllTour = () => {
  const [data, setData] = useState([]);
  const { tour_id } = useParams();
  const [loading, setLoading] = useState(false);

  const handleScroll = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get("/get_all_tours");
        setData(response.data);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  return (
    <div className="__fw" style={{ minHeight: "100dvh" }}>
      <div className="__mw">
        <HostNav />
        <div className="admin_listings">
          <div className="admin_listings_head">
            <div
              className="listings_item_text"
              style={{ marginBottom: "20px" }}
            >
              <h2>All Tours</h2>
            </div>
            <div>
              <div>
                <Link to={`/add-tour`}>
                  <div className="listings_item">
                    <h1>
                      <MdAdd fontSize="20" />
                    </h1>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="listings_item_text" style={{ marginBottom: "20px" }}>
            <div className="tour_form">
              <div className="allTour">
                {loading ? (
                  <div>Loading...</div> // Add your loading spinner or animation here
                ) : (
                  data?.map((item, i) => (
                    <Link to={`/update-tour/${item._id}`} key={i}>
                      <div className="tourCard">
                        <div className="image">
                          <img
                            src={`${process.env.REACT_APP_TOUR_UPLOAD_LINK}/${item.tourPhotos[0]}`}
                            alt=""
                          />
                          <div className="tourA">
                            <div className="trai-con">
                              <div className="trai-icon">
                                <img src={Tailormade} alt="seylontour" />
                              </div>
                              <div></div>
                            </div>
                          </div>
                        </div>
                        <div className="CSetwo">
                          <h2>{item.heading}</h2>
                          <span>{item.subHeading}</span>
                          <div className="icons">
                            <img src={taxi} alt="Transport Include" />
                            <img src={eye} alt="Sightseeing" />
                            <img src={map} alt="Locations" />
                            <img src={user} alt="English Speaking Chauffeur" />
                            <img src={bed} alt="Hotel Accommodation" />
                            <p>{item.description}</p>
                          </div>
                        </div>
                        <div className="CSeThree">
                          <h3>
                            PRICE ON <br /> REQUEST
                          </h3>
                          <a
                            className="shopButton4"
                            href="{% url 'sri-lanka-5-days-tour' %}"
                          >
                            VIEW TOUR
                          </a>
                          <br />
                          <h4>* * *</h4>
                        </div>
                      </div>
                    </Link>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllTour;
