import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import HostNav from '../../components/hostNav/HostNav';
import { MdAdd } from "react-icons/md";

const AllBlog = () => {
      const [blogData, setBlogData] = useState([]);
    const { tour_id } = useParams();
    const [loading, setLoading] = useState(false);

    const handleScroll = () => {
      window.scrollTo(0, 0);
    };

    useEffect(() => {
      const getBlogsAndCategories = async () => {
        try {
          // Fetch blogs
          const blogsResponse = await axios.get("/getBlog");
          setBlogData(blogsResponse.data);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      };

      getBlogsAndCategories();
    }, []);
  return (
    <div className="__fw" style={{ minHeight: "100dvh" }}>
      <div className="__mw">
        <HostNav />
        <div className="admin_listings">
          <div className="admin_listings_head">
            <div
              className="listings_item_text"
              style={{ marginBottom: "20px" }}
            >
              <h2>All Tours</h2>
            </div>
            <div>
              <div>
                <Link to={`/add-blog`}>
                  <div className="listings_item">
                    <h1>
                      <MdAdd fontSize="20" />
                    </h1>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="listings_item_text" style={{ marginBottom: "20px" }}>
            <div className="tour_form">
              <div className="allTour">
                {loading ? (
                  <div>Loading...</div> // Add your loading spinner or animation here
                ) : (
                  blogData?.map((item, i) => (
                    <Link to={`/update-blog/${item._id}`} key={i}>
                      <div className="_tourCard">
                        <div className="image">
                          <img
                            src={`${process.env.REACT_APP_IMAGE_BLOG_UPLOAD_LINK}/${item.blogPhotos[0]}`}
                            alt=""
                          />
                        </div>
                        <div className="CSetwo">
                          <h2>{item.heading}</h2>
                          <span>{item.category}</span>
                          <p>{item.blog}</p>
                          <span>{item.date}</span>
                        </div>
                      </div>
                    </Link>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllBlog
